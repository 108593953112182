<template>
    <!--FORMULAR-->
    <section class="pa-4 px-2 formular career">
        <!--SAVE BUTTON TOOLBAR-->
        <div class="toolbar-buttons">
            <v-btn v-if="checkPrivileg && edit" dark small depressed text @click="submit('saveItem')">
                <v-icon left>mdi-content-save-outline</v-icon>
                <span>{{ $t('common.save') }}</span>
            </v-btn>
        </div>
        <AdvancedMenu :items="advancedMenu" :handle="handleClick" v-if="checkPrivileg && edit"/>

        <!--FORMS-->
        <v-container v-if="checkPrivileg" fluid>
            <v-form @submit.prevent="submit" id="form" ref="form" class="formular">
                <!--BASIC DATA-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.basic_data') }}
                    </v-col>

                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_default" v-if="edit">
                                <v-text-field outlined dense color="gold"
                                              :label="$t('users.dialog.id')"
                                              v-model="user_data.id"
                                              disabled
                                />
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-select outlined dense color="gold"
                                          :label="$t('users.dialog.type')"
                                          item-text="name"
                                          item-value="id"
                                          :items="person_types"
                                          v-model="user_data.type"
                                          @change="checkType()"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold"
                                              :label="$t('users.dialog.title')"
                                              v-model="user_data.degree"
                                />
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold"
                                              :label="$t('users.dialog.name')"
                                              v-model="user_data.name"

                                              v-validate="'required'"
                                              data-vv-as="name"
                                              data-vv-name="user_name"
                                              :error-messages="errors.collect('user_name')"

                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold"
                                              :label="$t('users.dialog.surname')"
                                              v-model="user_data.surname"

                                              v-validate="'required'"
                                              data-vv-as="surname"
                                              data-vv-name="user_surname"
                                              :error-messages="errors.collect('user_surname')"
                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_email">
                                <v-text-field outlined dense color="gold"
                                              :label="$t('users.dialog.login_email')"
                                              v-model="user_data.email"

                                              v-validate="'required|email'"
                                              data-vv-name="user_login_email"
                                              :error-messages="errors.collect('user_login_email')"
                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold"
                                              :label="$t('users.dialog.tel')"
                                              v-model="user_data.phone"

                                              v-validate="'required'"
                                              data-vv-name="user_tel"
                                              :error-messages="errors.collect('user_tel')"
                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <!--PERSONAL DATA-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.personal_data') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-dialog
                            ref="dialog_date_of_birth"
                            v-model="modal_date_of_birth"
                            :return-value.sync="user_data.dateOfBirth"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="user_data.dateOfBirth"
                                    :label="$t('users.dialog.date_of_birth')"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined dense
                                    @click="$validator.validate('user_date_of_birth')"
                                    @focus="$validator.validate('user_date_of_birth')"
                                    color="gold"

                                    v-validate="'required|bornLimit'"
                                    data-vv-name="user_date_of_birth"
                                    :error-messages="errors.collect('user_date_of_birth')"
                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-date-picker
                                v-model="user_data.dateOfBirth"
                                scrollable
                                :first-day-of-week="1"
                                locale="sk"
                                color="gold"
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="gold"
                                    @click="modal_date_of_birth = false"
                                >
                                    {{ $t('common.cancel') }}
                                </v-btn>
                                <v-btn
                                    text
                                    color="gold"
                                    @click="$refs.dialog_date_of_birth.save(user_data.dateOfBirth);"
                                >
                                    {{ $t('common.ok') }}
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="user_id_number-input"
                                      :label="$t('users.dialog.id_number')"
                                      v-model="user_data.personalID"
                                      v-validate="'required'"
                                      data-vv-name="user_id_number"
                                      :error-messages="errors.collect('user_id_number')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>

                <!--PERSON DATA-->
                <v-row v-if="isPerson" :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.company_data') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="dic-input"
                                      :label="$t('users.dialog.dic')"
                                      v-model="user_data.taxId"

                                      v-validate="!isPerson ? 'required' : ''"
                                      data-vv-name="dic"
                                      :error-messages="errors.collect('dic')"
                        />
                    </v-col>
                </v-row>
                <!--COMPANY DATA-->
                <v-row v-else :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.company_data') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="user_company_name-input"
                                      :label="$t('users.dialog.company_name')"
                                      v-model="user_data.companyName"

                                      v-validate="'required'"
                                      data-vv-name="user_company_name"
                                      :error-messages="errors.collect('user_company_name')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="ico-input"
                                      :label="$t('users.dialog.ico')"
                                      v-model="user_data.companyId"

                                      v-validate="'required'"
                                      data-vv-name="ico"
                                      :error-messages="errors.collect('ico')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="dic-input"
                                      :label="$t('users.dialog.dic')"
                                      v-model="user_data.taxId"

                                      v-validate="!isPerson ? 'required' : ''"
                                      data-vv-name="dic"
                                      :error-messages="errors.collect('dic')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="ic_dph-input"
                                      :label="$t('users.dialog.ic_dph')"
                                      v-model="user_data.vatId"
                        />
                    </v-col>
                </v-row>

                <!--ADDRESS-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.address') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="user_street_and_number-input"
                                      :label="$t('users.dialog.street_and_number')"
                                      v-model="user_data.mainAddress.street"
                                      @keyup="$validator.validate('user_street_and_number')"

                                      v-validate="'required'"
                                      data-vv-name="user_street_and_number"
                                      :error-messages="errors.collect('user_street_and_number')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold"
                                      :label="$t('users.dialog.city')"
                                      v-model="user_data.mainAddress.city"
                                      @keyup="$validator.validate('user_city')"

                                      v-validate="'required'"
                                      data-vv-name="user_city"
                                      :error-messages="errors.collect('user_city')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold"
                                      :label="$t('users.dialog.psc')"
                                      v-model="user_data.mainAddress.zip"
                                      @keyup="$validator.validate('user_psc')"

                                      v-validate="'required'"
                                      data-vv-name="user_psc"
                                      :error-messages="errors.collect('user_psc')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-select outlined dense color="gold"
                                  :label="$t('users.dialog.country')"
                                  :items="getCountries"
                                  item-text="name"
                                  v-model="user_data.mainAddress.country"
                        />
                    </v-col>
                </v-row>

            </v-form>
        </v-container>

        <!--READER-->
        <v-container v-else fluid>
            <div v-if="data_ready">
                <!--BASIC DATA-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.basic_data') }}
                    </v-col>

                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.id') }}</v-col>
                                    <v-col cols="12" class="body-1 py-0">{{ user_data.id }}</v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{
                                            $t('users.dialog.type')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">
                                        {{ person_types.find(element => element.id === user_data.type).name }}
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{
                                            $t('users.dialog.title')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{ g_isDefined(user_data.degree) }}</v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{
                                            $t('users.dialog.name')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{ g_isDefined(user_data.name) }}</v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{
                                            $t('users.dialog.surname')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{ g_isDefined(user_data.surname) }}</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_email">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{
                                            $t('users.dialog.login_email')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{
                                            g_isDefined(user_data.email)
                                        }}
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{
                                            $t('users.dialog.tel')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{ g_isDefined(user_data.phone) }}</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <!--PERSONAL DATA-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.personal_data') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{
                                    $t('users.dialog.date_of_birth')
                                }}
                            </v-col>
                            <v-col cols="12" class="body-1 py-0">{{ g_convertDate(user_data.dateOfBirth) }}</v-col>
                        </v-row>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.id_number') }}</v-col>
                            <v-col cols="12" class="body-1 py-0">{{ user_data.personalID }}</v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <!--COMPANY DATA-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.company_data') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{
                                    $t('users.dialog.company_name')
                                }}
                            </v-col>
                            <v-col cols="12" class="body-1 py-0">{{ g_isDefined(user_data.companyName) }}</v-col>
                        </v-row>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.ico') }}</v-col>
                            <v-col cols="12" class="body-1 py-0">{{ g_isDefined(user_data.companyId) }}</v-col>
                        </v-row>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.dic') }}</v-col>
                            <v-col cols="12" class="body-1 py-0">{{ g_isDefined(user_data.taxId) }}</v-col>
                        </v-row>
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.ic_dph') }}</v-col>
                            <v-col cols="12" class="body-1 py-0">{{ g_isDefined(user_data.vatId) }}</v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <!--ADDRESS-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.address') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{
                                    $t('users.dialog.street_and_number')
                                }}
                            </v-col>
                            <v-col cols="12" class="body-1 py-0">{{
                                    g_isDefined(user_data.mainAddress.street)
                                }}
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.city') }}</v-col>
                            <v-col cols="12" class="body-1 py-0">{{ g_isDefined(user_data.mainAddress.city) }}</v-col>
                        </v-row>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.psc') }}</v-col>
                            <v-col cols="12" class="body-1 py-0">{{ g_isDefined(user_data.mainAddress.zip) }}</v-col>
                        </v-row>
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.country') }}</v-col>
                            <v-col cols="12" class="body-1 py-0">{{
                                    g_isDefined(user_data.mainAddress.country)
                                }}
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </v-container>

        <!--BOTTOM BUTTONS-->
        <v-container fluid>
            <v-row align="center" justify="center" class="mt-4">
                <v-col cols="12" align="center" v-if="checkPrivileg && !edit">
                    <v-btn class="gold lighten-1 white--text mr-1" depressed small @click="submit('createItem')">
                        {{ $t('common.registration') }}
                    </v-btn>
                    <v-btn class="white lighten-1 gold--text mr-1" depressed small outlined
                           @click="callbackFromCreateMode('createItemCancel')">{{ $t('common.cancel') }}
                    </v-btn>

                </v-col>
            </v-row>
        </v-container>

    </section>
</template>

<script>
import countries from "@/assets/countries";
import AuthMixin from "@/services/auth/auth_mixin";
import Permissions from "@/services/auth/permissions";
import {activation, checkEmail, resetPassword} from "@/api/auth";
import AdvancedMenu from "@/components/AdvancedMenu";
import {updateUser} from "@/api/users";
import {Utils} from "@/services/utils";

export default {
    name: "UserDialog",
    components: {AdvancedMenu},
    props: {
        edit: Boolean,
        id: undefined,
        card_item: undefined
    },
    mixins: [AuthMixin],
    data: () => ({
        data_ready: false,

        person_types: [
            {id: 'PERSON', name: "Osoba"},
            {id: 'COMPANY', name: "Firma"},
            {id: 'SELF_EMPLOYED', name: "SZČO"}
        ],

        user_data: {},
        modal_date_of_birth: false,
        isPerson: true,

        col_default: {cols: 12, sm: 6, md: 4, lg: 3},
        col_email: {cols: 12, sm: 12, md: 6, lg: 6},

        user_state: ''
    }),

    watch: {
        '$vuetify.breakpoint.width': function () {
            this.$parent.$emit('action', {func: 'setToolbarTitle', data: this.toolbar_title})
        },
    },

    computed: {
        isDark() {
            return this.$vuetify.theme.dark;
        },

        toolbar_title() {
            if (this.edit) {
                if (this.$vuetify.breakpoint.width < 451) {
                    return this.id;
                } else {
                    return this.user_data.name + " " + this.user_data.surname + " - " + this.id;
                }
            } else {
                return this.edit
            }
        },

        getSelectedUser() {
            return this.$store.getters.getSelectedUser;
        },

        getCountries() {
            return countries.country_codes;
        },

        checkPrivileg() {
            if (this.checkPermission(Permissions.ADMINISTRATOR) || this.checkPermission(Permissions.SUPPORTER)) {
                return true;
            }

            return !this.edit;
        },

        getItem() {
            let user_data = {};
            user_data.status = this.user_data.status;
            user_data.position = this.user_data.position;

            return user_data;
        },

        getAvailablePositions() {
            if (this.checkPermission(Permissions.ADMINISTRATOR) || this.edit) {
                return this.positions
            }

            let positions = [this.positions[0]]
            let userPosition = this.$store.getters.getUserPosition
            for (let i = 1; i < this.positions.length; i++) {
                if (this.positions[i].name !== userPosition) {
                    positions.push(this.positions[i])
                } else {
                    break
                }
            }

            return positions
        },

        positionsDisabled() {
            if (this.checkPermission(Permissions.ADMINISTRATOR)) {
                return false
            }

            let userPosition = this.$store.getters.getUserPosition
            return this.edit || userPosition.startsWith('P1') || userPosition.startsWith('P2') || userPosition.startsWith('P3') || userPosition.startsWith('P4') || userPosition.startsWith('P5')
        },

        advancedMenu() {
            return [
                {
                    label: 'reset_password', click() {
                        this.$root.$emit('overlay', true);
                        resetPassword(this.user_data.email).then(() => {
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'success',
                                icon: 'update',
                                msg: 'seller.reset_password_notify'
                            })
                            this.closeDialog();
                        })
                    }
                },
                {
                    disable_item: !this.user_data.active,
                    label: 'deactivate', click() {
                        this.$root.$emit('overlay', true);
                        activation(this.user_data.email).then(() => {
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'success',
                                icon: 'update',
                                msg: this.user_data.active ? 'users.deactivate_notify' : 'users.activate_notify'
                            })
                            this.user_data.active = !this.user_data.active
                            this.closeDialog();
                            this.$root.$emit('reload');
                        })
                    }
                },
                {
                    disable_item: this.user_data.active,
                    label: 'activate', click() {
                        this.$root.$emit('overlay', true);
                        activation(this.user_data.email).then(() => {
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'success',
                                icon: 'update',
                                msg: this.user_data.active ? 'users.deactivate_notify' : 'users.activate_notify'
                            })
                            this.user_data.active = !this.user_data.active
                            this.closeDialog();
                            this.$root.$emit('reload');
                        })
                    }
                }
            ]
        }
    },

    methods: {

        closeDialog() {
            if (this.edit) {
                this.$parent.$emit('action', {func: 'close', data: null});
            } else {
                this.$parent.$emit('call', {func: 'close', data: null});
            }
        },

        handleClick(index) {
            this.advancedMenu[index].click.call(this);
        },

        checkType() {
            this.isPerson = this.user_data.type === 'PERSON';
            this.validatorRefresh();
        },

        validatorRefresh() {

            //let vee = this.$validator;
            this.$validator.pause();

            this.$nextTick(() => {
                this.$validator.errors.clear();
                this.$validator.fields.items.forEach(f => f.reset());
                this.$validator.fields.items.forEach(f => this.errors.remove(f));
                this.$validator.resume();
            });
        },

        callback(e) {
            this.$parent.$emit('action', {func: e, data: this.id});
            this.$root.$emit('reload');
        },

        callbackFromCreateMode(_action) {
            this.$parent.$emit('call', {func: _action, data: this.user_data});
        },

        reset() {
            this.user_data = {};
        },

        submit(e) {
            this.$validator.validateAll().then(() => {
                this.$root.$emit('overlay', true);
                if (!this.$validator.errors.any()) {
                    this.save(e)
                } else {
                    this.$root.$emit('overlay', false);
                    this.$root.$emit('notification', {
                        type: 'error',
                        icon: 'info',
                        msg: 'notification.form_error'
                    });
                }
            })
        },

        save(e) {
            this.$root.$emit('overlay', true);

            let userForm = {
                email: this.user_data.email,

                name: this.user_data.name,
                surname: this.user_data.surname,
                degree: this.user_data.degree,

                dateOfBirth: this.user_data.dateOfBirth,
                personalID: this.user_data.personalID,

                type: this.user_data.type,

                companyId: this.user_data.companyId,
                companyName: this.user_data.companyName,
                taxId: this.user_data.taxId,
                vatId: this.user_data.vatId,

                phone: this.user_data.phone,

                mainAddress: {
                    street: this.user_data.mainAddress.street,
                    city: this.user_data.mainAddress.city,
                    zip: this.user_data.mainAddress.zip,
                    country: this.user_data.mainAddress.country
                },

                bankAccount: this.user_data.bankAccount,
            }

            if (this.edit) {
                updateUser(this.user_data.id, userForm).then(() => {
                    this.$root.$emit('overlay', false);
                    this.$root.$emit('notification', {
                        type: 'success',
                        icon: 'update',
                        msg: 'users.update_notify'
                    })
                    this.callback(e);
                })
            }
        }
    },

    created() {
        this.$validator.extend('checkExistingEmail', {
            validate: (value) => {
                return checkEmail(value)
                    .then(response => {
                        if (this.edit) {
                            return true
                        }
                        return !response.data;
                    })
            }
        });

        this.$validator.extend('bornLimit', {
            validate: (value) => {
                return Utils.is18YearsOld(value)
            }
        });

        this.user_data.mainAddress = {
            city: "",
            country: "Slovensko",
            street: "",
            zip: ""
        };

        this.user_data.type = 'PERSON';
        this.user_data.phone = '+421';

        this.validatorRefresh();
    },

    mounted() {

        if (this.edit) {
            this.$root.$emit('overlay', true);
            this.$store.dispatch('getUser', this.id)
                .then(() => {
                    this.user_data = this.getSelectedUser;
                    this.checkType();
                    this.data_ready = true;
                    this.$root.$emit('overlay', false);
                }).catch()
        }

    },
    destroyed() {
        //this.$store.dispatch('clearUser');
        this.reset();
    },
    updated() {
        this.$parent.$emit('action', {func: 'setToolbarTitle', data: this.toolbar_title});
    }
}
</script>

<style>
    .reader {
        background-color: rgba(201, 158, 68, 0.1);
        border: 1px solid rgba(0, 0, 0, 0.1);
    }

    #app.theme--dark .reader {
        background-color: rgba(0, 0, 0, 0.1);
    }
</style>